<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="content-wrapper relative">
    <div class=" navbar-full p-0">
      <vs-navbar
        class="the-header navbar-custom navbar-skelton sm:px-0 md:px-4 lg:px-24 xl:px-64"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mr-auto flex items-left"
        >
          <!-- <logo class="mr-4 fill-current text-primary" /> -->
          <img width="120" src="@/assets/images/logo/logo.svg"/>

        </router-link>
        <bookmarks
          class="vx-logo cursor-pointer mr-auto flex items-left"
          :navbarColor="navbarColor"
          v-if="windowWidth >= 992"
        />

        <!-- <i18n class="ml-2 mr-2" /> -->

        <!-- <cart-drop-down /> -->

        <!-- <notification-drop-down /> -->

        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
// import I18n from "./components/I18n.vue";
// import CartDropDown from "./components/CartDropDown.vue";
// import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import Logo from "../Logo.vue";

export default {
  name: "the-navbar-horizontal",
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Logo,
    Bookmarks,
    // I18n,
    // CartDropDown,
    // NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      if (this.isThemedark === "dark") {
        if (color === "#fff") {
          color = "#10163a";
        } else {
          color = "#262c49";
        }
      }

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-md";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>

<style lang="scss">
.shadow-md{
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.12), 0 2px 4px 0 rgba(255, 255, 255, 0.08) !important;
}

.content-wrapper{
  min-height: auto!important;
}

.the-header{
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}

.content-wrapper ul.vx-navbar__starred-pages{
  li.starred-page{
    position: relative;
    div{
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }
  @media (max-width: 1300px) {
    .the-header{
      padding-right: 1rem!important;
      padding-left: 1rem!important;
    }
  }

  @media (max-width: 1191px) {
    display: none!important;
  }

}
</style>
