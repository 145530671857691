<template>
  <div>
    <div
      class="the-navbar__user-meta flex items-center"
      v-if="activeUserInfo.name"
    >
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">
          {{ activeUserInfo.name }}
        </p>
        <!-- <span v-if="!activeUserInfo.branchName">
          {{ activeUserInfo.clinicName }} /
        </span>
        <span v-if="activeUserInfo.branchName">
          {{ activeUserInfo.branchName }}
        </span> -->
      </div>


      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <div class="con-img ml-3">
          <img
            key="onlineImg"
            src="@/assets/images/flags/ar.png"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
          />
        </div>

        <vs-dropdown-menu class="vx-navbar-dropdown">
          <ul style="min-width: 9rem">
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/UserPersonalDetails').catch(() => {})"
            >
              <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Profile</span>
            </li>

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-dark hover:text-white"
              @click="$router.push('/apps/email').catch(() => {})"
            >
              <feather-icon icon="MailIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Inbox</span>
            </li>

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/apps/todo').catch(() => {})"
            >
              <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Tasks</span>
            </li>

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/apps/chat').catch(() => {})"
            >
              <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Chat</span>
            </li>

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})"
            >
              <feather-icon icon="HeartIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Wish List</span>
            </li>

            <vs-divider class="m-1"/>

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="logout"
            >
              <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">Logout</span>
            </li>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <div class="btn-sp" @click="open = true">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="animate__animated animate__fadeIn menu-open-button" v-if="open"  @click="open=false">
      <span class="close" @click="open=false"><i class="t far fa-times-circle"></i></span>
      <div class="links-contnet">
        <ul>
          <li>
            <router-link to="/">Home</router-link>
            <router-link to="/memberCountries">Countries</router-link>
<!--            <a>Event</a>-->
            <router-link to="/payments">Payments</router-link>
            <router-link to="/competitions">competitions</router-link>
            <router-link to="/ambassadors">Ambassadors</router-link>
            <router-link to="/contactUs">Contact Us</router-link>
            <router-link to="/Register">Register</router-link>
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      TechNumber: '',
      open: false,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    userClinicInfo() {
      return this.$store.state.auth.userClinicInfo;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  watch: {
    '$route'() {
      this.open = false
    },
  },
  methods: {

    logout() {
      this.$store.commit("CLEAR_USER_INFO", null, {root: true});
      // this.$store.commit("REMOVE_USER_INFO",{ root: true });
      this.$store.dispatch("auth/logout");
    },
    mounted() {
      if (!this.activeUserInfo) {
        this.$router.push("admin/login");
      }
    }

  }
};
</script>


<style>
.btn-sp {
  cursor: pointer;
}

.btn-sp span {
  display: block;
  border: 1px solid #0093D2;
  width: 30px;
  border-radius: 7px;
  margin-bottom: 7px;
  background: #0093D2;
}

.menu-open-button {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: #0093D2;
  transition: 0.5s ease all;
}

.links-contnet {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.links-contnet li a {
  font-size: 25px;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  margin-bottom: 30px;
  text-align: center;
  color: white !important;
  text-decoration: none;
  transition: 0.3s ease all;
}

.links-contnet li a:hover{
  letter-spacing: 2px;
}




.close {
  position: absolute;
  top: 2%;
  right: 7%;
  font-size: 29px;
  cursor: pointer;
  opacity: 1;
  color: white;
}

.close:hover{
  color: white!important;
  opacity: 1;
}

.close i.t{
  color: #fffff!important;
}

@media (max-width: 991px) {

  .btn-sp {
    display: none;
  }

}
</style>
